<template>
    <v-form ref="form">
        <v-card class="card-shadow">
            <div class="card-title-wrapper pa-6">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="text-h1 font-weight-600 pa-0 mb-4">Licence Configuration</v-card-title>
                        <v-card-subtitle class="text-subtitle-1 pa-0">Important information to set up the hosting licence. </v-card-subtitle>
                    </v-col>
                </v-row>
            </div>
            <v-card-text v-if="configs.length" class="pa-6">
                <v-row>
                    <v-col cols="6">
                        <label for="licenceName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Licence Name</label>
                        <v-text-field
                            :rules="[rules.required]"
                            id="licenceName"
                            autocomplete="off"
                            hide-details="auto"
                            validate-on-blur
                            v-model="configs[0].licence.name"
                            outlined
                            class="input-style font-size-input text-color-gray mt-0 mb-0"
                            placeholder="Name">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <label for="hostingProvider" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Hosting Provider</label>
                        <v-text-field
                            :rules="[rules.required]"
                            id="hostingProvider"
                            autocomplete="off"
                            hide-details="auto"
                            validate-on-blur
                            v-model="configs[0].licence.hostingProvider"
                            outlined
                            class="input-style font-size-input text-color-gray mt-0 mb-0"
                            placeholder="Digital Ocean">
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <p class="text-subtitle-2 pa-0 ma-0 pb-1 font-weight-bold">Service Connection</p>
                        <v-divider class="py-2"></v-divider>
                        <v-row>
                            <v-col cols="6">
                                <label for="license_host" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Host</label>
                                <v-text-field
                                    :rules="[rules.required]"
                                    id="license_host"
                                    autocomplete="off"
                                    hide-details="auto"
                                    validate-on-blur
                                    v-model="configs[0].licence.licencingServiceConnection.host"
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                                    placeholder="localhost">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label for="license_port" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Port</label>
                                <v-text-field
                                    :rules="[rules.required, rules.port]"
                                    id="license_port"
                                    autocomplete="off"
                                    hide-details="auto"
                                    validate-on-blur
                                    v-model="configs[0].licence.licencingServiceConnection.port"
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                                    placeholder="80"
                                    type="number">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-switch v-model="configs[0].licence.licencingServiceConnection.secure" inset hide-details label="Secure" color="#6F70FF" class="switch mb-6 mt-0 pl-1"> </v-switch>
                    </v-col>
                    <v-col cols="6">
                        <p class="text-subtitle-2 pa-0 ma-0 pb-1 font-weight-bold">Instance Connection</p>
                        <v-divider class="py-2"></v-divider>
                        <v-row>
                            <v-col cols="6">
                                <label for="instance_host" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Host</label>
                                <v-text-field
                                    :rules="[rules.required]"
                                    id="instance_host"
                                    autocomplete="off"
                                    hide-details="auto"
                                    validate-on-blur
                                    v-model="configs[0].licence.instanceConnection.host"
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                                    placeholder="localhost">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <label for="instance_port" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Port</label>
                                <v-text-field
                                    :rules="[rules.required, rules.port]"
                                    id="instance_port"
                                    autocomplete="off"
                                    hide-details="auto"
                                    validate-on-blur
                                    v-model="configs[0].licence.instanceConnection.port"
                                    outlined
                                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                                    placeholder="80"
                                    type="number">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-switch v-model="configs[0].licence.instanceConnection.secure" inset hide-details label="Secure" color="#6F70FF" class="switch mb-6 mt-0 pl-1"> </v-switch>
                    </v-col>
                </v-row>
                <v-btn
                    :loading="isTestingConnection"
                    :disabled="isTestingConnection"
                    @click="testConnection()"
                    elevation="0"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mr-2"
                    color="#6F70FF">
                    Test Connection
                </v-btn>
                <v-btn :disabled="!didConnectionSucceed" @click="saveLicence()" elevation="0" height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm" color="#6F70FF">
                    Update Licence
                </v-btn>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                port: (value) => value > 0 || '*Port must be larger than 0'
            },
            didConnectionSucceed: false,
            isTestingConnection: false
        };
    },
    mixins: [makeFindMixin({ service: 'configs' })],
    methods: {
        async saveLicence() {
            if (this.$refs.form.validate()) {
                try {
                    if (!this.process.VUE_APP_BYPASS_LICENCING) {
                        await this.$store.dispatch('licencing-communicator/patch', [
                            this.configs[0].licence.licencingServiceConnection.instance_id,
                            {
                                connection: {
                                    host: this.configs[0].licence.licencingServiceConnection.host,
                                    port: this.configs[0].licence.licencingServiceConnection.port,
                                    secure: this.configs[0].licence.licencingServiceConnection.secure
                                },
                                licencingInformation: {
                                    host: this.configs[0].licence.instanceConnection.host,
                                    port: this.configs[0].licence.instanceConnection.port,
                                    secure: this.configs[0].licence.instanceConnection.secure,
                                    name: this.configs[0].licence.name,
                                    hosting_provider: this.configs[0].licence.hostingProvider
                                }
                            }
                        ]);
                    }

                    await this.configs[0].save();
                    this.showSuccess('Licence successfully saved!');
                    this.didConnectionSucceed = false;
                } catch (error) {
                    this.showError(error);
                }
            }
        },
        async testConnection() {
            // do something
            if (this.$refs.form.validate()) {
                this.didConnectionSucceed = false;
                try {
                    if (this.process.VUE_APP_BYPASS_LICENCING) {
                        this.showSuccess('Connection succeeded. Licencing service disabled');
                        this.didConnectionSucceed = true;
                        this.isTestingConnection = false;
                        return;
                    }


                    this.isTestingConnection = true;
                    let response = await this.$store.dispatch('licencing-communicator/find', {
                        query: {
                            secure: this.configs[0].licence.licencingServiceConnection.secure,
                            host: this.configs[0].licence.licencingServiceConnection.host,
                            port: this.configs[0].licence.licencingServiceConnection.port
                        }
                    });
                    if (response[0]?.success) {
                        this.showSuccess('Connection succeeded');
                        this.didConnectionSucceed = true;
                        this.isTestingConnection = false;
                        this.configs[0].licence.licencingServiceConnection.interval = response[0].interval;
                    } else throw new Error('Failed to connect to licencing service');
                } catch (error) {
                    this.showError(error);
                    this.didConnectionSucceed = false;
                    this.isTestingConnection = false;
                }
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        configsParams() {
            return {
                query: {
                    $sort: { id: 1 }
                }
            };
        }
    }
};
</script>
